import { mapState, mapActions } from 'vuex'
import { getYear } from '@/helpers'

export default {
  name: 'TFilesSidebar',
  data () {
    return {
      activeYear: false
    }
  },
  props: {
    widgets: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    month: {
      type: String,
      default: ''
    },
    year: {
      type: Number,
      default: ''
    }
  },
  computed: {
    ...mapState({
      currentMonty: ({ files }) => files.currentMonty,
      currentYear: ({ files }) => files.currentYear,
      isLoading: ({ files }) => files.widgetLoading,
      totalFilesNewShipment: ({ files }) => files.totalFilesNewShipment
    }),
    filteredWidgets () {
      if ( getYear() === this.year && this.widgets.length > 0 ) {
        this.widgets[0].new = this.totalFilesNewShipment
      }
      return this.widgets
    }
  },
  methods: {
    ...mapActions('files', ['getFilesNewShipment', 'getFilesWaitingReturn', 'getFilesFinished']),
    getMonth (month) {
      return this.$t(`months.${month}`)
    },
    widgetClasses: (current, month, cyear, year, isLoading) => {
      let _classes = ''
      _classes += (cyear === year && current === month) ? 'active-line' : ''
      _classes += isLoading ? 'loading' : ''
      return _classes
    },
    toMonth (e, month, selectedYear) {
      e.preventDefault()

      this.$route.params.year = selectedYear

      this.link = {
        name: `files`,
        params: {
          ...this.$route.params,
          ...{ month }
        }
      }

      if (typeof this.$route.params.type === 'undefined') {
        this.$router.push('404').catch(() => {})
      }

      const { commit } = this.$store

      commit('files/SET_CURRENT_MONTH', month)
      commit('files/SET_CURRENT_YEAR', selectedYear)

      this.getFilesWaitingReturn({ year: selectedYear, month: month })
      this.getFilesFinished({ year: selectedYear, month: month })

      this.$router.push(this.link).catch(() => {})
    },

    checkActiveYear () {
      if (this.currentYear === Number(this.year)) {
        this.activeYear = true
      }
    }
  },

  mounted () {
    this.checkActiveYear()
  }
}
